@import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");

body {
  margin: 0px;
  background-color: black; /* Dark background to enhance the glow effect */
}

#container {
  position: absolute;
  margin: auto;
  width: 100vw;
  height: 80pt;
  top: 0;
  bottom: 0;
  filter: url(#threshold) blur(0.6px);
}

#text1, #text2 {
  position: absolute;
  width: 100%;
  display: inline-block;
  font-family: "Raleway", sans-serif;
  font-size: 80pt;
  text-align: center;
  user-select: none;
  color: white; /* White text color */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), /* Subtle white glow */
               0 0 20px rgba(255, 255, 255, 0.6),
               0 0 30px rgba(255, 255, 255, 0.4),
               0 0 40px rgba(255, 255, 255, 0.2);
}
