/* LaserTag.css */
body {
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(to bottom right, #070630 0%, #060454 100%);
    min-height: 100vh;
}

.animation-container {
    display: block;
    position: fixed; /* Changed to fixed positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
    width: 800px;
    max-width: 100%;
}

.lightning-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
}

.boom-container,
.boom-container.second {
    position: absolute;
    display: flex;
    width: 80px;
    height: 80px;
    text-align: center;
    align-items: center;
    transform: translate(-50%, -50%);
}

.boom-container {
    left: 25%;
    top: 50%;
}

.boom-container.second {
    left: 75%;
    top: 50%;
}

.lightning {
    position: absolute;
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    transform-origin: 6px 6px;
    animation-name: woosh;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
    animation-direction: alternate;
}

.lightning.white {
    background-color: white;
    box-shadow: 0px 50px 50px 0px rgba(255, 255, 255, 0.3);
}

.lightning.red {
    background-color: #fc7171;
    box-shadow: 0px 50px 50px 0px rgba(252, 113, 113, 0.3);
    animation-delay: 0.2s;
}


.shape {
    display: inline-block;
    position: relative;
    opacity: 0;
    transform-origin: center center;
}

.shape.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    transform-origin: 50% 80%;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    margin-left: -15px;
    border-width: 0 2.5px 5px 2.5px;
    border-color: transparent transparent #42e599 transparent;
    animation-name: boom-triangle;
}

.shape.triangle.big {
    margin-left: -25px;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #fade28 transparent;
    animation-name: boom-triangle-big;
}

.shape.disc {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #d15ff4;
    animation-name: boom-disc;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

.shape.circle {
    width: 20px;
    height: 20px;
    animation-name: boom-circle;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    border-radius: 100%;
    margin-left: -30px;
}

.shape.circle.white {
    border: 1px solid white;
}

.shape.circle.big {
    width: 40px;
    height: 40px;
    margin-left: 0px;
}

.shape.circle.big.white {
    border: 2px solid white;
}

@keyframes woosh {
	0% {
		width: 12px;
		transform: translate(0px, 0px) rotate(-35deg);
	}
	15% {
		width: 50px;
	}
	30% {
		width: 12px;
		transform: translate(214px, -150px) rotate(-35deg);
	}
	30.1% {
		transform: translate(214px, -150px) rotate(46deg);
	}
	50% {
		width: 110px;
	}
	70% {
		width: 12px;
		transform: translate(500px, 150px) rotate(46deg);
	}
	70.1% {
		transform: translate(500px, 150px) rotate(-37deg);
	}
	
	85% {
		width: 50px;
	}
	100% {
		width: 12px;
		transform: translate(700px, 0) rotate(-37deg);
	}
}

@keyframes boom-circle {
	0% {
		opacity: 0;
	}
	5% {
		opacity: 1;
	}
	30% {
		opacity: 0;
		transform: scale(3);
	}
	100% {
	}
}

@keyframes boom-triangle-big {
	0% {
		opacity: 0;
	}
	5% {
		opacity: 1;
	}
	
	40% {
		opacity: 0;
		transform: scale(2.5) translate(50px, -50px) rotate(360deg);
	}
	100% {
	}
}

@keyframes boom-triangle {
	0% {
		opacity: 0;
	}
	5% {
		opacity: 1;
	}
	
	30% {
		opacity: 0;
		transform: scale(3) translate(20px, 40px) rotate(360deg);
	}
	
	100% {
	}
}

@keyframes boom-disc {
	0% {
		opacity: 0;
	}
	5% {
		opacity: 1;
	}
	40% {
		opacity: 0;
		transform: scale(2) translate(-70px, -30px);
	}
	100% {
		
	}
}

/* Footer Styles */
.footer {
    color: white;
    font-size: 10px;
    position: fixed;
    bottom: 0;
    font-weight: 200;
    padding: 10px 20px;
}

.footer a,
.footer a:hover,
.footer a:focus,
.footer a:visited {
    color: #c6c6c6;
}
